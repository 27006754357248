import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../common/constStyles';
import { ScrollUp } from '../components/ScrollUp';
import { useCategory } from '../hooks/useGategory';

const DivStyled = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  && .text_main {
    font-size: 4rem;
    font-weight: bold;
    padding: 0;
    line-height: 1.2;
  }

  && .text_main.regular {
    color: ${colors.colorTextDark};
  }

  && .text_second {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0;
    line-height: 1.5;
    color: ${colors.colorTextDark};
  }

  @media (max-width: 768px) {
    && .text_main {
      font-size: 2rem;
    }

    && .text_second {
      font-size: 1rem;
    }
  }
`;

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  margin: 0 auto;
  
  && .text_main_second {
    font-size: 3rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;

    && .text_main_second {
      font-size: 2rem;
    }
  }
`;

export const AllServicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { CATEGORY_SERVICES, GROUP_CATEGORY_SERVICES } = useCategory();

  return (
      <DivStyled>
          <Helmet>
              <title>All Services | ServiceIn</title>
              <meta name="description" content="Find your service"/>
          </Helmet>
          <ScrollUp/>
          <FlexStyled vertical justify={'left'} gap={30}>
              <p className={'text_main_second'}>{t('All services')}</p>
              <div>
                  {

                      Object.keys(GROUP_CATEGORY_SERVICES).map((key) => {
                        const groupCategoryService = GROUP_CATEGORY_SERVICES[key];

                        return (<Flex key={key} gap={10} vertical style={{ marginBottom: '40px' }}>
                            <p className={'text_second'} >{groupCategoryService.caption}</p>
                            <Flex gap={20} wrap={'wrap'}>
                              {groupCategoryService.category.map((category) => (
                                  <a href={'#'}>
                                      <div key={category} style={{
                                        cursor: 'pointer', fontSize: '1.1em', padding: '10px', border: `1px solid ${colors.colorBorder}`,
                                      }} color={'processing'}>
                                      {CATEGORY_SERVICES[category]}
                              </div>
                                  </a>
                              ))}
                            </Flex>
                        </Flex>);
                      })
                  }
              </div>
          </FlexStyled>
      </DivStyled>

  );
};
