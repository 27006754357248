import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }
`;

export const HomeTextBlockSecond: React.FC = () => {
  const { t } = useTranslation();

  return (
      <FlexStyled vertical justify={'center'} gap={30}>
          <h1 className={'text_main'}>{t('Connecting Users and Service Providers')}</h1>
          <div>
            <h2 style={{ display: 'inline' }} className={'text_second'}>{t('Welcome to ServiceIn, your one-stop platform for services and opportunities in Canada.')}</h2>
            <h2 style={{ display: 'inline' }} className={'text_second'}>{t(' Whether you are new or local, providing a service or looking for a service, we are here to connect you with the right people and resources.')}</h2>
          </div>
      </FlexStyled>
  );
};
